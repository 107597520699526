import type { FC } from "react";
import React from "react";

const FaceIdIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="64"
      viewBox="0 0 63 64"
      fill="none"
    >
      <path
        fill="url(#a)"
        d="M1.863 20.633c1.09 0 1.688-.633 1.688-1.758v-8.543c0-4.043 2.144-6.152 6.117-6.152h8.648c1.09 0 1.723-.598 1.723-1.688 0-1.09-.633-1.687-1.723-1.687H9.563C3.375.805.175 3.969.175 10.156v8.719c0 1.125.597 1.758 1.687 1.758Zm59.239 0c1.125 0 1.687-.633 1.687-1.758v-8.719c0-6.117-3.2-9.351-9.422-9.351h-8.719c-1.09 0-1.722.597-1.722 1.687s.633 1.688 1.722 1.688h8.649c3.867 0 6.117 2.11 6.117 6.152v8.543c0 1.125.598 1.758 1.688 1.758ZM28.793 36.945h.281c2.918 0 4.43-1.511 4.43-4.394V22.25c0-.914-.527-1.441-1.441-1.441-.95 0-1.477.527-1.477 1.441v10.582c0 .703-.563 1.16-1.16 1.16H28.3c-.844 0-1.442.598-1.442 1.406 0 1.02.668 1.547 1.934 1.547Zm-9.492-7.664c1.195 0 2.004-.808 2.004-2.004v-4.465c0-1.16-.809-1.968-2.004-1.968-1.125 0-1.899.808-1.899 1.968v4.465c0 1.196.774 2.004 1.899 2.004Zm24.082 0c1.16 0 1.933-.808 1.933-2.004v-4.465c0-1.16-.773-1.968-1.933-1.968-1.196 0-1.969.808-1.969 1.968v4.465c0 1.196.773 2.004 1.969 2.004Zm-12.164 18.14c3.761 0 7.558-1.581 10.3-4.323.247-.282.458-.633.458-1.16 0-.844-.633-1.407-1.442-1.407-.457 0-.773.14-1.3.668-1.9 1.934-4.958 3.34-8.016 3.34-2.989 0-6.012-1.371-8.016-3.34-.422-.457-.773-.668-1.3-.668-.81 0-1.442.563-1.442 1.407 0 .492.21.843.457 1.124 2.672 2.848 6.539 4.36 10.3 4.36ZM9.562 63.419h8.754c1.09 0 1.723-.598 1.723-1.688 0-1.09-.633-1.687-1.723-1.687H9.668c-3.973 0-6.117-2.11-6.117-6.152v-8.543c0-1.125-.598-1.723-1.688-1.723-1.125 0-1.687.598-1.687 1.723v8.718c0 6.188 3.199 9.352 9.386 9.352Zm35.086 0h8.72c6.222 0 9.421-3.234 9.421-9.352v-8.718c0-1.125-.598-1.723-1.687-1.723-1.09 0-1.688.598-1.688 1.723v8.543c0 4.043-2.25 6.152-6.117 6.152h-8.649c-1.09 0-1.722.598-1.722 1.688 0 1.09.633 1.687 1.722 1.687Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="-8.5"
          x2="71.5"
          y1="30.5"
          y2="30.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E3763" />
          <stop offset=".526" stopColor="#5B4855" />
          <stop offset="1" stopColor="#8A6947" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FaceIdIcon;
